/*
   Copyright 2014 Nebez Briefkani
   floppybird - main.css

   Licensed under the Apache License, Version 2.0 (the "License");
   you may not use this file except in compliance with the License.
   You may obtain a copy of the License at

       http://www.apache.org/licenses/LICENSE-2.0

   Unless required by applicable law or agreed to in writing, software
   distributed under the License is distributed on an "AS IS" BASIS,
   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   See the License for the specific language governing permissions and
   limitations under the License.
*/

.small_score_styles {
   color: white;
   font-size: 12px;
   font-family: "Oswald", sans-serif;
}

.large_score_styles {
   color: white;
   font-size: 24px;
   font-family: "Oswald", sans-serif;
   font-weight: bolder;
}

.logout_btn {
   background-color: transparent;
   background-image: none;
   font-weight: 700;
   font-family: "Oswald", sans-serif;
   margin-top: 1em;
   margin-right: 0.5em;
   display: inline-block;
   text-align: center;
   white-space: nowrap;
   vertical-align: middle;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   border: 1px solid transparent;
   padding: .375rem .75rem;
   font-size: 1rem;
   line-height: 1.5;
   border-radius: .25rem;
   -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
   transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
   -o-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
   transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
   transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
}

.popUpHeader {
   font-family: "Oswald", sans-serif;
   font-size: 16px;
   text-align: center;
   color: black
}

.popUpInput {
   border: black 1px solid;
   width: 100%;
   font-size: 20px;
   padding:5px 0px;
   font-family: "Oswald", sans-serif;
   margin: 15px 0;
   border-radius: 10px;
   color: black;
   user-select: text
}
input, select {
   user-select: text
}

.popUpButton{
   text-align: center;
   font-size: 20px;
   padding: 0px 40px;
   font-family: "Oswald", sans-serif;
   border-radius: 5px;
   min-width: 30px;
}

/* Flexbox version */
::-webkit-scrollbar {
   width: 0px;  /* remove scrollbar space */
   background: transparent;  /* optional: just make scrollbar invisible */
}

.headerImage{
   max-height: 200px;
   width: auto;
}

.headerBody{
   width: 100%;
   font-family: "Oswald", sans-serif;
   text-align: center;
   font-size: 20px;
}

.headerText{
   text-align: center;
   width: 100%;
   font-family: "Oswald", sans-serif;
   font-size: 45px;
}

@keyframes animLand {
   0% { background-position: 0px 0px; }
   100% { background-position: -335px 0px; }
}

@keyframes animSky {
   0% { background-position: 0px 100%; }
   100% { background-position: -275px 100%; }
}

@keyframes animBird {
   from { background-position: 0px 0px; }
   to { background-position: 0px -96px; }
}

@keyframes animPipe {
   0% { left: 900px; }
   100% { left: -100px; }
}

@keyframes animCeiling {
   0% { background-position: 0px 0px; }
   100% { background-position: -63px 0px; }
}


*,
*:before,
*:after
{
   /* border box */
   -moz-box-sizing: border-box;
   -webkit-box-sizing: border-box;
   box-sizing: border-box;
   /* gpu acceleration */
   -webkit-transition: translate3d(0,0,0);
   /* select disable */
   -webkit-touch-callout: none;
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
}

html,
body
{
   height: 100%;
   overflow: hidden;
   /*font-family: monospace;*/
   /*font-size: 12px;*/
   /*color: #fff;*/
}

#gamecontainer
{
   /*position: relative;*/
   width: 100%;
   height: 100%;
   min-height: 525px;
}

/*
Screen - Game
*/
#gamescreen
{
   position: absolute;
   width: 100%;
   height: 100%;
   -ms-touch-action: manipulation;
   touch-action: manipulation;
}

#sky
{
   position: absolute;
   top: 0;
   width: 100%;
   height: 80%;
   /* background-image: url('../images/sky.png'); */
   background-repeat: repeat-x;
   background-position: 0px 100%;
   /* background-color: #4ec0ca; */

   animation: animSky 7s linear infinite;
}

#flyarea
{
   position: absolute;
   bottom: 0;
   height: 420px;
   width: 100%;
}

#ceiling
{
   position: absolute;
   top: -16px;
   height: 16px;
   width: 100%;
   /* background-image: url('../images/ceiling.png'); */
   background-repeat: repeat-x;

   animation: animCeiling 481ms linear infinite;
}

#land
{
   position: absolute;
   bottom: 0;
   width: 100%;
   height: 20%;
   /* background-image: url('../images/land.png'); */
   background-repeat: repeat-x;
   background-position: 0px 0px;
   /* background-color: #ded895; */

   animation: animLand 2516ms linear infinite;
}

#bigscore
{
   position: absolute;
   top: 20px;
   left: 150px;
   z-index: 100;
}

#bigscore img
{
   display: inline-block;
   padding: 1px;
}

#splash
{
   position: absolute;
   opacity: 0;
   top: 75px;
   left: 65px;
   width: 188px;
   height: 170px;
   /* background-image: url('../images/splash.png'); */
   background-repeat: no-repeat;
}

#scoreboard
{
   position: absolute;
   display: none;
   opacity: 0;
   top: 64px;
   left: 43px;
   width: 236px;
   height: 280px;
   background-image: url('../images/scoreboard.png');
   background-repeat: no-repeat;

   z-index: 1000;
}

#currentscore
{
   position: absolute;
   top: 105px;
   left: 107px;
   width: 104px;
   height: 14px;
   text-align: right;
}

#currentscore img
{
   padding-left: 2px;
}

#highscore
{
   position: absolute;
   top: 147px;
   left: 107px;
   width: 104px;
   height: 14px;
   text-align: right;
}

#highscore img
{
   padding-left: 2px;
}

#replay
{
   position: absolute;
   opacity: 0;
   top: 205px;
   left: 61px;
   height: 115px;
   width: 70px;
   cursor: pointer;
}

.boundingbox
{
   position: absolute;
   display: none;
   top: 0;
   left: 0;
   width: 0;
   height: 0;
   border: 1px solid red;
}

#player
{
   left: 60px;
   top: 200px;
}

.bird
{
   position: absolute;
   width: 68px;
   height: 48px;
   /* background-image: url('../images/bird.png'); */

   /* -webkit-animation: animBird 300ms steps(4) infinite; */
   /* animation: animBird 300ms steps(4) infinite; */
}

.pipe
{
   position: absolute;
   left: -100px;
   width: 52px;
   height: 100%;
   z-index: 10;

   animation: animPipe 7500ms linear;
}

.pipe_upper
{
   position: absolute;
   top: 0;
   width: 52px;
   /* background-image: url('../images/pipe.png'); */
   background-repeat: repeat-y;
   background-position: center;
}

.pipe_upper:after
{
   content: "";
   position: absolute;
   bottom: 0;
   width: 52px;
   height: 26px;
   /* background-image: url('../images/pipe-down.png'); */
}

.pipe_lower
{
   position: absolute;
   bottom: 0;
   width: 52px;
   /* background-image: url('../images/pipe.png'); */
   background-repeat: repeat-y;
   background-position: center;
}

.pipe_lower:after
{
   content: "";
   position: absolute;
   top: 0;
   width: 52px;
   height: 26px;
   /* background-image: url('../images/pipe-up.png'); */
}

#footer
{
   position: absolute;
   bottom: 3px;
   left: 3px;
}

#footer a,
#footer a:link,
#footer a:visited,
#footer a:hover,
#footer a:active
{
   display: block;
   padding: 2px;
   text-decoration: none;
   color: #fff;
}
